<template>
  <DashboardTemplate v-loading="loading">
    <Layout class="pd-t-5">
      <div>
        <span @click="$router.push('/result')" class="cr-pointer font-14"><i class="el-icon-arrow-left"></i>
          ย้อนกลับ</span>
      </div>
      <div>
        <div class="is-flex js-between font-18 border-bottom">
          <h3 class="mg-y-6">{{ categoryName }}</h3>
          <p class="text-grey-light font-24 mg-y-6">
            ระดับ
            <span class="font-24 font-weight-bold text-dark">{{
              totalScore
            }}</span>
          </p>
        </div>
        <div class="mg-t-7 graph-height" v-loading="chartLoading" element-loading-text="กำลังโหลด...">
          <div v-if="chartLoading == false">
            <el-row :gutter="20" type="flex" class="flex-warp" align="middle">
              <el-col :lg="15" :xl="14" :offset="0">
                <radar-chart :data="chartData" :axisSize="22" :dataAvg="
                  compare && user.recommendStatus == 1 && statusAvg
                    ? chartDataAvg
                    : []
                " :indicator="indicator" :axisLabel="true" :radius="widthMax >= 768 ? '70%' : '60%'"
                  :nameFontSize="widthMax >= 768 ? 18 : 13" :height="widthMax >= 768 ? '370px' : '300px'" showTooltip />
              </el-col>
              <el-col :lg="9" :xl="10" :offset="0">
                <ul class="level-result">
                  <li>
                    Level 1 ไม่มีความพร้อม/ มีระดับความพร้อมในการส่งออกต่ำ
                  </li>
                  <li>Level 2 มีระดับความพร้อมในการส่งออกปานกลางค่อนข้างต่ำ</li>
                  <li>Level 3 มีระดับความพร้อมในการส่งออกปานกลางค่อนข้างสูง</li>
                  <li>Level 4 มีระดับความพร้อมในการส่งออกสูง</li>
                </ul>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="text-center pd-y-7" v-if="user.recommendStatus == 1">
          <el-tooltip :content="'เปรียบเทียบในประเภทธุรกิจและขนาดเดียวกัน'" placement="top">
            <el-switch v-model="compare" active-text="เปรียบเทียบ" active-color="#07509C" @change="switchCompare">
            </el-switch>
          </el-tooltip>
        </div>
        <div class="text-center pd-t-5" v-if="user.recommendStatus == 1">
          <el-button v-if="openMenu" onclick="window.open('/export/result', '_blank')" type="primary"
            class="rounded-sm">
            <div v-on:click="onPrint">ดาวน์โหลดผลประเมิน</div>
          </el-button>
        </div>
      </div>
    </Layout>
    <div class="bg-user-second">
      <Layout>
        <div class="mg-t-5">
          <div class="is-flex js-end text-level">
            <p class="text-grey-light font-18 mg-y-6">ระดับ</p>
          </div>
          <div v-for="(item, index) in questionsSort" :key="index" class="cate-list mg-b-5">
            <div class="is-flex js-between pd-5 cr-pointer" :id="`header${index}`" @click="expandCollap(index)">
              <div class="is-flex">
                <div class="is-flex ai-center head-cate">
                  {{ item.questionName }}
                </div>
              </div>
              <div class="level-point is-flex ai-center js-end">
                {{ item.level | dotRoundDown }}
                <span class="text-grey">
                  <i class="fas fa-angle-right" :id="`icon${index}`" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div :id="`ul${index}`" class="
                overflow-hidden
                font-18
                content-scroll
                view-question
                wangEditor-container
                pd-x-5
              " style="
                visibility: hidden;
                opacity: 0;
                max-height: 0;
                transition: all 0.25s ease-out;
              ">
              <div class="result-des show-desktop wangEditor-txt" v-html="item.extendDescription"></div>
              <div class="editor-contents result-des show-mobile wangEditor-txt" v-html="item.description"></div>
              <div v-if="item.tools.length > 0 && openMenu">
                <el-row>
                  <el-col :xs="5" :sm="4" :md="3" :lg="4" :offset="0">เครื่องมือ:</el-col>
                  <el-col :xs="19" :sm="20" :md="19" :lg="20" :offset="0"><span v-for="(tool, index) in item.tools"
                      :key="tool.id">
                      <a target="_blank" rel="noopener noreferrer" :href="tool.url" v-if="tool.url"
                        class="color-primary mg-y-0 d-block">
                        {{
                          tool.name
                        }}{{ index == item.tools.length - 1 ? "" : ", " }}
                      </a>
                      <p v-else class="mg-y-0">
                        {{
                          tool.name
                        }}{{ index == item.tools.length - 1 ? "" : ", " }}
                      </p>
                    </span></el-col>
                </el-row>
              </div>
              <div v-if="item.course.length > 0 && openMenu" class="mg-b-5">
                <el-row>
                  <el-col :xs="5" :sm="4" :md="3" :lg="4" :offset="0">หลักสูตร:</el-col>
                  <el-col :xs="19" :sm="20" :md="19" :lg="20" :offset="0"><span v-for="tool in item.course"
                      :key="tool.id">
                      <a target="_blank" rel="noopener noreferrer" v-if="tool.url" @click="linkExternal(tool.url)"
                        class="color-primary mg-y-0 d-block">
                        {{ tool.name }}
                      </a>
                      <p v-else class="mg-y-0">
                        {{ tool.name }}
                      </p>
                    </span></el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  </DashboardTemplate>
</template>
<script>
import RadarChart from "@/echart/Radar";
import { HTTP } from "@/service/axios";
import DashboardTemplate from "@/template/DashboardTemplate";
import Layout from "@/template/LayoutDashboardTemplate";
import axios from "axios";
export default {
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  components: {
    DashboardTemplate,
    Layout,
    RadarChart,
  },
  computed: {
    statusAvg ()
    {
      if ( this.chartDataAvg.length > 0 )
      {
        let filter = this.chartDataAvg[ 0 ].value.findIndex( ( a ) => a > 0 );
        if ( filter > -1 )
        {
          return true;
        } else
        {
          return false;
        }
      } else
      {
        return false;
      }
    },
    user ()
    {
      return this.$store.state.user;
    },
    token ()
    {
      return this.$store.state.token;
    },
    categoryName ()
    {
      let filter = this.categoryList.filter(
        ( filter ) => filter.cateId == this.cate
      );
      if ( filter.length > 0 )
      {
        return filter[ 0 ].cateName;
      } else
      {
        return "";
      }
    },
    questions ()
    {
      let filter = this.categoryList.filter(
        ( filter ) => filter.cateId == this.cate
      );
      if ( filter.length > 0 )
      {
        // console.log("questions", filter);
        let arr = [];
        filter[ 0 ].question.forEach( ( element, index ) =>
        {
          if ( index == 0 )
          {
            arr.push( element );
          } else
          {
            arr.push( filter[ 0 ].question[ filter[ 0 ].question.length - index ] );
          }
        } );
        filter[ 0 ].question = arr;
        return arr;
      } else
      {
        return [];
      }
    },
    questionsSort ()
    {
      let data = JSON.parse( JSON.stringify( this.questions ) );
      // console.log("questionsSort", data);
      let arr = data.map( ( item, index ) =>
      {
        if ( index == 0 )
        {
          return item;
        } else
        {
          return data[ data.length - index ];
        }
      } );
      return arr;
    },
    totalScore ()
    {
      let sum = 0;
      this.questions.map( ( item ) =>
      {
        sum += item.level;
      } );

      let avg = this.$options.filters.dotRoundDown( sum / this.questions.length );
      if ( isNaN( avg ) )
      {
        return 0;
      } else
      {
        return avg;
      }
    },
    indicator ()
    {
      let arr = [];
      this.questions.map( ( item, index ) =>
      {
        //console.log("index", item.questionName);
        arr.push( {
          name:
            this.widthMax > 768
              ? item.questionName
                .replace( " ", "\n" )
                .replace( "และ", "และ\n" )
                .replace( "บวน", "บวน\n" )
                .replace( "ลอก", "\nลอก" )
                .replace( "การบริหารการ", "การบริหาร\nการ" )
              : this.addNewLine(
                item.questionName,
                index == 0 ? 30 : this.widthMax < 376 ? 10 : 14
              ),
          max: 5,
        } );
      } );
      return arr;
    },
    chartData ()
    {
      let arr = [
        {
          value: [],
          name: "บริษัท: " + this.companyName,
          // label: {
          //   show: true,
          //   formatter: function(params) {
          //     return params.value.toFixed(1);
          //   }
          // }
        },
      ];

      this.questions.map( ( item ) =>
      {
        arr[ 0 ].value.push(
          this.$options.filters.dotRoundDown( Number( item.level ) )
        );
      } );
      return arr;
    },
    openMenu ()
    {
      return this.$store.state.openMenu;
    },
  },
  data ()
  {
    return {
      loading: true,
      chartLoading: true,
      compare: true,
      categoryList: [],
      assessmentLevel: "",
      assessmentMeanGraph: [],
      chartDataAvg: [],
      companyName: "",
      cate: "",
      widthMax: window.innerWidth,
      ssoUrl: process.env.VUE_APP_SSO_URL,
    };
  },
  async mounted ()
  {
    this.fetchStatusMenu();
    this.setParams();
    await this.fetchData();
  },
  methods: {
    async linkExternal ( link )
    {
      try
      {
        let obj = {
          username: "",
          userId: this.user.id,
          activity: "คลิกลิงก์หลักสูตร",
          status: "Success",
          request: link,
          response: "",
        };
        await HTTP.post( `create/logs`, obj ).catch( ( err ) =>
        {
          console.log( "create/logs", err );
        } );
        window.open( link, "_blank" );
      } catch ( error )
      {
        console.log( "Open link Error", error );
      }
    },
    async linkSSO ( link )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${ this.token }`;
      let userData = await HTTP.get( `SSO` ).catch( ( e ) =>
      {
        if ( e == "Error: Request failed with status code 401" )
        {
          this.checkAuth();
        } else
        {
          if ( e != "Error: timeout of 120000ms exceeded" )
          {
            this.alertCatchError( e );
          }
        }
        console.log( "fetchResult error", e );
      } );
      if ( userData.data.success )
      {
        this.getSSOToken( userData.data, link );
      }
    },
    async getSSOToken ( obj, link )
    {
      let config = {
        headers: {
          "Accept-Token-App-Id": "QTHROCgDfaFvc4XizL5nMwj6ubUd9S8N",
        },
      };
      let objData = {
        personal_type: obj.token.person_type,
        username: obj.token.username,
        email: obj.token.email,
        prefix: obj.token.prefix,
        gender: obj.token.gender,
        firstName: obj.token.firstName,
        lastName: obj.token.lastName,
        identity_id: obj.token.identity_card ? obj.token.identity_card : 0,
        uid: this.user.id,
        juristic_id: obj.token.juristicId,
        companyName: obj.token.companyName,
      };
      // let config = {
      //   headers: {
      //     "Accept-Token-App-Id": "QTHROCgDfaFvc4XizL5nMwj6ubUd9S8N",
      //   },
      // };
      // let objData = {
      //   personal_type: 2,
      //   username: "member2",
      //   companyName: "company",
      //   email: "acchorikez@gmail.com",
      //   prefix: 1,
      //   gender: "M",
      //   firstName: "test",
      //   lastName: "test_lastname",
      //   identity_id: "",
      //   juristic_id: 12345678910123,
      // };
      let res = await axios.post(
        `${ this.ssoUrl }api/v1/registration/member/store`,
        objData,
        config
      );
      this.updateLogToken( res.data, obj.logsId );
      if ( res.data.status )
      {
        this.returnSSO( res.data, link, obj );
      }
    },
    async returnSSO ( token, link, userData )
    {
      axios.defaults.headers.common.Authorization = `Bearer ${ token.result.access_token }`;
      let result = link.includes( "https" );
      if ( !result )
      {
        link = link.replace( "http", "https" );
      }
      let returns = await axios
        .get( `${ this.ssoUrl }validate/redirect?return_url=${ link }` )
        .then( ( res ) =>
        {
          if ( res.data.status )
          {
            window.open( res.data.result, "_blank" );
          }
          return res;
        } )
        .catch( ( e ) =>
        {
          console.log( "redirect error:", e );
          return e;
        } );
      this.createLog( returns, userData, token.result.access_token );
      // if (res.status == 200) {
      //   window.location.href = `${this.ssoUrl}validate/redirect?return_url=${link}`;
      // }
    },
    async createLog ( data, user, token )
    {
      let obj = {
        username: user.token.username,
        userId: this.user.id,
        activity: "SSO Register",
        status: data.data.status,
        request: token,
        response: JSON.stringify( data.data ),
      };
      await HTTP.post( `create/logs`, obj ).catch( ( err ) =>
      {
        console.log( "create/logs", err );
      } );
    },
    async updateLogToken ( obj, id )
    {
      let data = {
        logsId: id,
        response: JSON.stringify( obj.result ),
        status: obj.status,
      };
      await HTTP.post( `SSO/logs`, data ).catch( ( err ) =>
      {
        console.log( "SSO/logs", err );
      } );
    },
    async fetchData ()
    {
      if ( this.user.recommendStatus == 1 )
      {
        await this.fetchResult();
        await this.assessmentMean();
      } else
      {
        await this.fetchDefault();
        await this.assessmentMean();
      }
    },
    async fetchDefault ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${ this.token }`;
      await HTTP.get( `default/result` )
        .then( ( res ) =>
        {
          if ( res.data.success )
          {
            this.assessmentLevel = res.data.obj.assessmentLevel;
            this.categoryList = res.data.obj.categories;
            this.companyName = res.data.obj.companyName;
          }
        } )
        .catch( ( e ) =>
        {
          if ( e == "Error: Request failed with status code 401" )
          {
            this.checkAuth();
          } else
          {
            if ( e != "Error: timeout of 120000ms exceeded" )
            {
              this.alertCatchError( e );
            }
          }
          console.log( "fetchResult error", e );
        } )
        .finally( () =>
        {
          this.loading = false;
        } );
    },
    switchCompare ()
    {
      this.chartLoading = true;
      setTimeout( () =>
      {
        this.chartLoading = false;
      }, 500 );
    },
    async assessmentMean ()
    {
      let obj = {
        year: "all",
        businessType: "all",
        businessSize: this.assessmentLevel,
        industryType: this.user.industryType,
        cateIndustryType: this.user.cateIndustryType,
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${ this.token }`;
      await HTTP.post( `dashboard/recommend`, obj )
        .then( ( res ) =>
        {
          //console.log("assessmentMean", res);
          if ( res.data.success )
          {
            this.assessmentMeanGraph = res.data.obj[ this.assessmentLevel ];

            for (
              let i = 1;
              i <= Object.keys( this.assessmentMeanGraph ).length;
              i++
            )
            {
              if (
                typeof this.assessmentMeanGraph[ "cate" + i ] != "undefined" &&
                this.categoryName == this.assessmentMeanGraph[ "cate" + i ].name
              )
              {
                this.assessmentMeanGraph =
                  this.assessmentMeanGraph[ "cate" + i ].factor;
              }
            }

            this.assessmentMeanGraph = this.assessmentMeanGraph.map(
              ( val, index ) =>
              {
                if ( index == 0 )
                {
                  return this.$options.filters.dotRoundDown(
                    Number( Number( val.point ) )
                  );
                } else
                {
                  return Number(
                    this.$options.filters.dotRoundDown(
                      Number(
                        this.assessmentMeanGraph[
                          this.assessmentMeanGraph.length - index
                        ].point
                      )
                    )
                  );
                }
              }
            );
            this.chartDataAvg = [
              {
                // label: {
                //   show: true,
                //   formatter: function(params) {
                //     return params.value.toFixed(1);
                //   }
                // },
                value: this.assessmentMeanGraph,
                name: "เปรียบเทียบในธุรกิจและขนาดเดียวกัน",
              },
            ];
          }
        } )
        .catch( ( e ) =>
        {
          if ( e == "Error: Request failed with status code 401" )
          {
            this.checkAuth();
          } else
          {
            if ( e != "Error: timeout of 120000ms exceeded" )
            {
              this.alertCatchError( e );
            }
          }
          console.log( "fetchResult error", e );
        } )
        .finally( () =>
        {
          this.loading = false;
          this.chartLoading = false;
        } );
    },
    fetchStatusMenu ()
    {
      let obj = {
        token: this.token,
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${ obj.token }`;
      HTTP.get( `close/function` )
        .then( ( res ) =>
        {
          if ( res.data.success )
          {
            let status = res.data.obj.status == 1 ? true : false;
            this.$store.commit( "SET_OPEN_MENU", status );
          }
        } )
        .catch( ( e ) =>
        {
          if ( e == "Error: Request failed with status code 401" )
          {
            this.checkAuth();
          } else
          {
            if ( e != "Error: timeout of 120000ms exceeded" )
            {
              this.alertCatchError( e );
            }
          }
          console.log( "FETCH_OPEN_MENU error", e.response );
        } );
    },
    onPrint ()
    {
      let obj = {
        activity: "ดาวน์โหลดผลประเมิน",
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${ this.token }`;
      HTTP.post( `create/logs`, obj )
        .then( ( res ) =>
        {
          if ( res.data.success )
          {
            // var name = "_blank";
            // var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];
            // const url = "/export/result";
            // const win = window.open(url, specs, name);
          }
        } )
        .catch( ( e ) =>
        {
          if ( e == "Error: Request failed with status code 401" )
          {
            this.checkAuth();
          } else
          {
            if ( e != "Error: timeout of 120000ms exceeded" )
            {
              this.alertCatchError( e );
            }
          }
          console.log( "onPrint factor error", e );
        } );
    },
    setParams ()
    {
      if ( this.routeParams != null )
      {
        if ( typeof this.routeParams.cate != "undefined" )
        {
          this.cate = this.routeParams.cate;
        }
      }
    },
    expandCollap ( index )
    {
      let element = document.getElementById( "icon" + index );
      let display = document.getElementById( "ul" + index ).style.visibility;
      if ( display == "hidden" )
      {
        element.className = "fas fa-angle-right fa-rotate-90  ";
        document.getElementById( "ul" + index ).style.visibility = "visible";
        document.getElementById( "ul" + index ).style.paddingBottom = "15px";
        document.getElementById( "ul" + index ).style.maxHeight = "500px";
        document.getElementById( "ul" + index ).style.overflowY = "auto";
        document.getElementById( "ul" + index ).style.border = "1 solid #a8cff0";
        setTimeout( () =>
        {
          document.getElementById( "ul" + index ).style.opacity = 1;
          // document.getElementById("ul" + index).style.height = "auto";
        }, 250 );
      } else
      {
        element.className = "fas fa-angle-right";
        document.getElementById( "ul" + index ).style.opacity = 0;
        document.getElementById( "ul" + index ).style.maxHeight = "0px";
        document.getElementById( "ul" + index ).style.paddingBottom = "0px";
        document.getElementById( "ul" + index ).style.overflow = "auto";
        setTimeout( () =>
        {
          document.getElementById( "ul" + index ).style.visibility = "hidden";
          // document.getElementById("ul" + index).style.height = 0;
        }, 250 );
      }
    },
    async fetchResult ()
    {
      let obj = {
        userId: this.user.id,
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${ this.token }`;
      await HTTP.post( `recommend/result`, obj )
        .then( ( res ) =>
        {
          if ( res.data.success )
          {
            this.assessmentLevel = res.data.obj.assessmentLevel;
            this.categoryList = res.data.obj.categories;
            this.companyName = res.data.obj.companyName;
          }
        } )
        .catch( ( e ) =>
        {
          if ( e == "Error: Request failed with status code 401" )
          {
            this.checkAuth();
          } else
          {
            if ( e != "Error: timeout of 120000ms exceeded" )
            {
              this.alertCatchError( e );
            }
          }
          console.log( "fetchResult error", e );
        } );
    },
  },
};
</script>

<style scoped>
i {
  vertical-align: middle;
}
</style>
